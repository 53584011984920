import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Heading, VStack, FormControl, Input, Button, HStack, Icon, Spinner, Flex, IconButton, FlatList, Text, Pressable } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HookableComponent } from 'react-hookable-component';
import useScreenType from "react-screentype-hook";
import {GuestListBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, deleteColor, lightGreen, titleTextColor, backgroundGradientColor, footerTextColor} from '../constants/colors';
import TextField from '@material-ui/core/TextField';
import "./styles.css";

const styles = theme => ({
  paper: {
    flex: 1,
    // padding: 5
  },
  textField: {
    borderWidth: 1, borderRadius: 5, borderColor: borderColor
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display:'flex'
  },
});

class AddEditMultipleGuests extends HookableComponent {
  constructor() {
    super();
    this.state = {
      loading: false,
      fName: '',
      lName: '',
      listViewData: [],
      selectedEventDate: '',
      message: '',
      messageType: '',
    };
  }


deleteItem = (name) => {
  const filteredData = this.state.listViewData.filter(item => (item.first_Name+item.last_Name) !== name);
  this.setState({ listViewData: filteredData });
}

  addGuest = () => {
    if (!this.state.fName) {
      alert('Enter First Name');
      return;
    }
    if (!this.state.lName) {
      alert('Enter Last Name');
      return;
    }
    let tempArray = this.state.listViewData;
    let temp = {first_Name: this.state.fName, last_Name: this.state.lName};
    tempArray.push(temp);
    this.setState({listViewData: tempArray, fName: '', lName: ''});
  }

  componentDidMount() {

  }

  handleChange = (e) => {
    //console.log(e.target.name);
    //console.log(e.target.value);
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  onSubmit = async() => {
    const {listViewData} = this.state;

    if(listViewData.length > 50){
      alert("Upto 50 Guests are Allowed");
      return;
    }
    var UserDate = this.state.selectedEventDate;
    var ToDate = new Date();

    if (!this.state.selectedEventDate) {
      alert('Please select a event date');
      return;
    }

    if (new Date(UserDate).getDate() < ToDate.getDate()) {
      alert("The Date must be today or later date");
      return;
    }

    let savedata = {
      event_Date: this.state.selectedEventDate,
      guests: listViewData
    }
    // console.log('savedata: ',savedata);
    this.setState({loading: true});
    const {addGuestCall} = this.props;
    let abcd = await addGuestCall(savedata);
    // console.log('abcd: ',abcd);
    var myobj = JSON.parse(JSON.stringify(abcd));
    // console.log('myObj: ',myobj);

    if (abcd.success === false) {
      this.setState({loading: false});
      alert(myobj.data.data.message);
      return;
    }
    this.setState({loading: false});
    this.props.history.goBack();
  }

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }


  render() {
    const {
      loading,
      fName,
      lName,
      listViewData,
      selectedEventDate,
    } = this.state;
    let {classes} = this.props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const screenType = useScreenType();

    return(
      <div className={classes.paper}>
        <img
          style={{width: (screenType.isMobile) ? window.innerWidth * (90 / 100) : window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={GuestListBanner}
          alt={'GuestListBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 5}} >
            <HStack space={3} m={6} alignItems='center' justifyContent='space-between' mb={3}>
              {/* <FormControl> */}
                {/* <FormControl.Label>
                  First Name
                </FormControl.Label> */}
                <Input
                  // variant="rounded"
                  w='40%'
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={fName => this.setState({fName})}
                  value={fName}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'First Name'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                  returnKeyType='next'
                />
              {/* </FormControl>
              <FormControl> */}
                {/* <FormControl.Label>
                  Last Name
                </FormControl.Label> */}

                <Input
                  // variant="rounded"
                  w='40%'
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={lName => this.setState({lName})}
                  value={lName}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Last Name'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                  returnKeyType='done'
                />
              {/* </FormControl> */}
              <Pressable
                // w="15%"
                style={{borderRadius: 5, justifyContent: 'center', alignItems: 'center', background: backgroundGradientColor}}
                onPress={() => this.addGuest()}
                underlayColor={underlayColor}>
                <HStack space={2} justifyContent="space-between" alignItems="center" p="2">
                  <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="md" color={"white"}/>}/>
                  <Heading _dark={{
                    color: "white"
                  }} color="white" fontWeight="medium" size="xs">
                    Add Guest
                  </Heading>
                </HStack>
              </Pressable>
            </HStack>

            <Heading _dark={{
              color: titleTextColor
            }} color={titleTextColor} fontWeight="medium" size="md" ml={6} mt={3}>
              New Guest(s):
            </Heading>

            <VStack m={6}>
              <FlatList
                style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
                data={listViewData}
                renderItem={({item, index}) => (
                  <Pressable
                    style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                    underlayColor={underlayColor}>
                    <Box flex="1">
                      <HStack space={2} alignItems="center">
                        <Text style={{
                          color: listMainTextColor,
                          fontSize: 16,
                          width: 35,
                          padding: 7,
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          textAlign: 'center'
                        }}>{index+1}</Text>
                        <Text
                          style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            padding: 7,
                            width: '90%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                          }}>
                          {item.first_Name+" "+item.last_Name}
                        </Text>
                        <IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'trash']} size="lg" color={deleteColor}/>}/>} onPress={() => { let name = item.first_Name+item.last_Name; this.deleteRow(name)}} />
                      </HStack>
                    </Box>
                  </Pressable>
                )}
              />
            </VStack>
            <HStack space={10} m={6} w='75%' alignItems='center' justifyContent='space-between' mb={10} mt={10}>
              <FormControl w='63%'>
                <FormControl.Label style={{color: titleTextColor}}>
                  Event Date
                </FormControl.Label>
                <TextField
                  id="date"
                  name='selectedEventDate'
                  type="date"
                  value={selectedEventDate}
                  defaultValue="2017-05-24"
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.handleChange}
                />
              </FormControl>

              <Pressable
                style={{borderRadius: 5, width: '35%', marginTop: 25, justifyContent: 'center', alignItems: 'center', background: backgroundGradientColor}}
                onPress={() => this.onSubmit()}
                disabled={loading}
                underlayColor={underlayColor}>
                <HStack space={3} justifyContent="space-between" alignItems="center" p="4">
                  <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/>
                  <Heading _dark={{
                    color: "white"
                  }} color="white" fontWeight="medium" size="sm">
                    Save
                  </Heading>
                </HStack>
              </Pressable>

            </HStack>
          </VStack>
          {this.getValue('EventsPageMessage') && (
            <VStack style={{marginTop: 10, backgroundColor: lightGreen, justifyContent: 'center', alignItems: 'center',marginHorizontal:10, borderRadius: 5}}>
              <Text style={{padding: 5, color: footerTextColor, textAlign:'center'}}>
                {this.getValue('EventsPageMessage')}
              </Text>
            </VStack>
          )}
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

AddEditMultipleGuests.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddEditMultipleGuests);
