import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, VStack, HStack, Icon, Spinner, IconButton, Fab, FlatList, Text, Pressable, Heading } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HookableComponent } from 'react-hookable-component';
import useScreenType from "react-screentype-hook";
import {ManagePetsBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, deleteColor, lightGreen} from '../constants/colors';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class ManagePets extends HookableComponent {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  closeRow(rowMap, rowKey) {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  }

  deleteRow = async guestID => {
    this.setState({loading: true});
    const {deletePet, getAllPet} = this.props;
    // console.log('@deleteRow');
    // console.log(guestID);
    await deletePet(guestID);
    await getAllPet();
    this.setState({loading: false});
  };

  componentDidMount = async () => {
    this.setState({loading: true});
    const {getAllPet} = this.props;
    await getAllPet();
    this.setState({loading: false});
  };

  render() {
    const {classes, allPets} = this.props;
    const {loading} = this.state;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const screenType = useScreenType();
    return (

      <div className={classes.paper}>
        <img
          style={{width: (screenType.isMobile) ? window.innerWidth * (90 / 100) : window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={ManagePetsBanner}
          alt={'ManagePetsBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <HStack space={2} justifyContent="flex-end">
            <Pressable
              style={{borderRadius: 5, justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
              onPress={() => {this.props.history.push({pathname: 'add-edit-pet', state: {data: null}});}}
              underlayColor={underlayColor}>
              <HStack space={2} justifyContent="space-between" alignItems="center" p="2">
                <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="md" color={"white"}/>}/>
                <Heading _dark={{
                  color: "white"
                }} color="white" fontWeight="medium" size="xs">
                  Add Pet Details
                </Heading>
              </HStack>
            </Pressable>
          </HStack>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <HStack space={2} alignItems="center">
              {/* <Text style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  width: '6%',
                  padding: 7,
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  textAlign: 'center'
              }}>S.No.</Text> */}
              <Text
                style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  padding: 7,
                  width: '16%',
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  // textAlign: 'center'
                }}>
                Pet Name
              </Text>
              <Text
                style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  padding: 7,
                  width: '12%',
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  // textAlign: 'center'
                }}>
                Pet Type
              </Text>
              <Text
                style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  padding: 7,
                  width: '12%',
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  // textAlign: 'center'
                }}>
                Pet Breed
              </Text>
              <Text
                style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  padding: 7,
                  width: '11%',
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  // textAlign: 'center'
                }}>
                Pet Color
              </Text>
              <Text
                style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  padding: 7,
                  width: '14%',
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  // textAlign: 'center'
                }}>
                Reg. No.
              </Text>
              <Text
                style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  padding: 7,
                  width: '20%',
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  // textAlign: 'center'
                }}>
                Notes
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '9%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Image
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '6%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Action
              </Text>
            </HStack>
            <FlatList
              style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
              data={allPets}
              renderItem={({item, index}) => (
                <Pressable
                  style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                  onPress={() => {this.props.history.push({pathname: 'add-edit-pet', state: {data: item}});}}
                  underlayColor={underlayColor}>
                  <Box flex="1">
                    <HStack space={2} alignItems="center">
                      {/* <Text style={{
                          color: listMainTextColor,
                          fontSize: 16,
                          width: '6%',
                          paddingVertical: '2%',
                          paddingHorizontal: 7,
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          textAlign: 'center'
                      }}>{index+1}</Text> */}
                      <Text
                        style={{
                          color: listMainTextColor,
                          // fontSize: 16,
                          paddingVertical: '2%',
                          paddingHorizontal: 7,
                          width: '16%',
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          // textAlign: 'center'
                        }}>
                        {item?.pets_Name}
                      </Text>
                      <Text
                        style={{
                          color: listMainTextColor,
                          // fontSize: 16,
                          paddingVertical: '2%',
                          paddingHorizontal: 7,
                          width: '12%',
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          // textAlign: 'center'
                        }}>
                        {item?.pet_Type}
                      </Text>
                      <Text
                        style={{
                          color: listMainTextColor,
                          // fontSize: 16,
                          paddingVertical: '2%',
                          paddingHorizontal: 7,
                          width: '12%',
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          // textAlign: 'center'
                        }}>
                        {item?.breed}
                      </Text>
                      <Text
                        style={{
                          color: listMainTextColor,
                          // fontSize: 16,
                          paddingVertical: '2%',
                          paddingHorizontal: 7,
                          width: '11%',
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          // textAlign: 'center'
                        }}>
                        {item?.pet_Color}
                      </Text>
                      <Text
                        style={{
                          color: listMainTextColor,
                          // fontSize: 16,
                          paddingVertical: '2%',
                          paddingHorizontal: 7,
                          width: '14%',
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          // textAlign: 'center'
                        }}>
                        {(item?.reg_Num)?item?.reg_Num:'-'}
                      </Text>
                      <Text
                        style={{
                          color: listMainTextColor,
                          // fontSize: 16,
                          paddingVertical: '2%',
                          paddingHorizontal: 7,
                          width: '20%',
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          // textAlign: 'center'
                        }}>
                        {(item?.notes)?item?.notes:'-'}
                      </Text>
                      <VStack w='8.6%' p={1} style={{borderRightColor: borderColor, borderRightWidth: 1}}>
                        <img
                          style={{width: 60, height: 60, resizeMode: 'contain', alignSelf: 'center'}}
                          src={`data:image/png;base64,${item.photoThumbnail}`}
                          alt={`Avatar n°${index + 1}`}
                        />
                      </VStack>
                      <VStack w='5.7%'>
                        <IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'trash']} size="lg" color={deleteColor}/>}/>} onPress={() => {this.deleteRow(item.petID)}} />
                      </VStack>
                    </HStack>
                  </Box>
                </Pressable>
              )}
            />
          </VStack>
        </div>
        {/* <Fab onPress={() => {this.props.history.push({pathname: 'add-edit-pet', state: {data: null}});}}
        renderInPortal={false} shadow={2} placement="bottom-right" bottom={20} size="sm" icon={<Icon as={<FontAwesomeIcon icon={['fas', 'plus']} size="lg" color="white"/>} />} /> */}
        {/* <Fab onPress={() => {this.props.history.push({pathname: 'add-edit-pet', state: {data: null}});}}
        renderInPortal={false} shadow={2} placement="top-right" top={20} size="sm" icon={<Icon as={<FontAwesomeIcon icon={['fas', 'plus']} size="lg" color="white"/>} />} /> */}
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

ManagePets.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ManagePets);
