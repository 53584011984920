import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Heading, HStack, VStack, Icon, Spinner, IconButton, Switch, Fab, FlatList, Text, Pressable } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import { HookableComponent } from 'react-hookable-component';
import useScreenType from "react-screentype-hook";
import {GuestListBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, deleteColor} from '../constants/colors';
import SearchBar from "material-ui-search-bar";

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class ManageGuestList extends HookableComponent {
  constructor() {
    super();
    this.arrayholder = [];
    this.state = {
      switchVal: false,
      showExpired: 0,
      allData: [],
      value: '',
      loading: false,
    };
  }

  closeRow(rowMap, rowKey) {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  }

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }

  deleteRow = async guestID => {
    if(!this.getValue('GuestListAllowDeletion')){
      alert('You community has disabled Guest Deletion');
      return;
    }
    this.setState({loading: true});
    const {deleteGuest, getAllGuest} = this.props;
    // console.log('@deleteRow');
    // console.log(guestID);
    await deleteGuest(guestID);
    await getAllGuest(this.state.showExpired);
    this.setState({allData: this.props.allGuest});
    this.arrayholder = this.props.allGuest;
    this.setState({loading: false});
  };

  componentDidMount = async () => {
    this.setState({loading: true});
    const {getAllGuest} = this.props;
    await getAllGuest(this.state.showExpired);
    this.setState({allData: this.props.allGuest});
    this.arrayholder = this.props.allGuest;
    this.setState({loading: false});
    //console.log('Khatam'+this.props.getAllGuestStatus+"  er: "+this.props.getAllGuestError);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.allGuest !== this.state.allData) {
      //Perform some operation
      //console.log("allGuest: ",nextProps.allGuest);
      this.setState({ allData: nextProps.allGuest });
      this.arrayholder = nextProps.allGuest;
    }
  }

  search = text => {
    try {
      const newData = this.arrayholder.filter(item => {
        let fullName = '';
        if (item.first_Name === null) {
          fullName = item.last_Name;
        } else {
          fullName = item.first_Name+" "+item.last_Name;
        }
        const itemData = `${fullName.toLowerCase()}`;
        const textData = text.toLowerCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({
        allData: newData,
      });
    } catch (e) {

    }

  }

  handleChange = async(event) => {
    // console.log('event: ', event);
    this.setState({loading: true});
    let val = 0;
    if (event) {
        val = 1;
      } else {
        val = 0;
    }
    this.setState({switchVal: event, showExpired: val });
    await this.props.getAllGuest(val);
    this.setState({allData: this.props.allGuest});
    this.arrayholder = this.props.allGuest;
    this.setState({loading: false});
  };


  render() {
    let {classes} = this.props;
    let {switchVal, loading, allData} = this.state;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const screenType = useScreenType();
    return (
      <div className={classes.paper}>
        <img
          style={{width: (screenType.isMobile) ? window.innerWidth * (90 / 100) : window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={GuestListBanner}
          alt={'GuestListBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <HStack space={2} justifyContent="space-between">
            <HStack space={3} justifyContent="space-between" alignItems="center" pl="2" pr="2" style={{borderRadius: 5, background: 'linear-gradient(45deg, #0B7645 30%, #042B1C 90%)'}}>
              <Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg" color={"white"}/>}/>
              <Heading _dark={{
                  color: "white"
              }} color="white" fontWeight="medium" size="sm">
                Show Expired Guests
              </Heading>
              <Switch size="md" colorScheme="emerald" isChecked={switchVal} onToggle={this.handleChange}/>
            </HStack>
            <SearchBar
              style={{width: "40%", borderRadius: 5, borderWidth:1, borderColor: searchBarBorderColor}}
              value={this.state.value}
              onChange={(newValue) => this.search(newValue)}
              onCancelSearch={() => this.search('')}
              //onRequestSearch={() => this.search(this.state.value)}
            />
            <Pressable
              style={{borderRadius: 5, justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(45deg, #0B7645 30%, #042B1C 90%)'}}
              onPress={() => {this.props.history.push({pathname: 'add-edit-guest', state: {data: null}});}}
              underlayColor={underlayColor}>
              <HStack space={3} justifyContent="space-between" alignItems="center" pl="4" pr="4">
                <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/>
                <Heading _dark={{
                    color: "white"
                }} color="white" fontWeight="medium" size="sm">
                  Add Guest
                </Heading>
              </HStack>
            </Pressable>
          </HStack>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <HStack space={2} alignItems="center">
              {/* <Text style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  width: '6%',
                  padding: 7,
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  textAlign: 'center'
              }}>S.No.</Text> */}
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '30%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Guest Name
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '20%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Valid From
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '20%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Valid To
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '15%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Type
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    // padding: 7,
                    width: '15%',
                    textTransform: 'capitalize',
                    // borderRightColor: borderColor,
                    // borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Action
              </Text>
            </HStack>
            <FlatList
              style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
              data={allData}
              renderItem={({item, index}) => (
                <Pressable
                  style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                  onPress={() => {this.props.history.push({pathname: 'add-edit-guest', state: {data: item}});}}
                  underlayColor={underlayColor}>
                  <Box flex="1">
                    <HStack space={2} alignItems="center">
                      {/* <Text style={{
                          color: listMainTextColor,
                          fontSize: 16,
                          width: '6%',
                          padding: 7,
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          textAlign: 'center'
                      }}>{index+1}</Text> */}
                      <Text
                        style={{
                            color: listMainTextColor,
                            // fontSize: 16,
                            padding: 7,
                            width: '30%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {(item?.first_Name === null) ? item?.last_Name : item?.first_Name+" "+item?.last_Name}
                      </Text>
                      <Text
                        style={{
                            color: listMainTextColor,
                            // fontSize: 16,
                            padding: 7,
                            width: '20%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {(item?.start_Date) ? moment(item.start_Date).format("M/D/YY") : "-"}
                      </Text>
                      <Text
                        style={{
                            color: listMainTextColor,
                            // fontSize: 16,
                            padding: 7,
                            width: '20%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {(item?.end_Date) ? moment(item.end_Date).format("M/D/YY") : "-"}
                      </Text>
                      <Text
                        style={{
                            color: listMainTextColor,
                            // fontSize: 16,
                            padding: 7,
                            width: '15%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {(item.end_Date && item.start_Date) ? 'Temporary' : 'Permanent'}
                      </Text>
                      <VStack w='14.6%'>
                        <IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'trash']} size="md" color={deleteColor}/>}/>} onPress={() => {this.deleteRow(item.guestID)}} />
                      </VStack>
                    </HStack>
                  </Box>
                </Pressable>
              )}
            />
          </VStack>
        </div>
        {/* <Fab onPress={() => {this.props.history.push({pathname: 'add-edit-guest', state: {data: null}});}}
          renderInPortal={false} shadow={2} placement="bottom-right" bottom={20} size="sm" icon={<Icon as={<FontAwesomeIcon icon={['fas', 'plus']} size="lg" color="white"/>} />} />
          <Fab onPress={() => {this.props.history.push({pathname: 'add-edit-guest', state: {data: null}});}}
        renderInPortal={false} shadow={2} placement="top-right" top={20} size="sm" icon={<Icon as={<FontAwesomeIcon icon={['fas', 'plus']} size="lg" color="white"/>} />} /> */}
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

ManageGuestList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ManageGuestList);
